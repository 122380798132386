import { default as indexDaotkr3TZ7Meta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/index.vue?macro=true";
import { default as resultsMxHAlq0HK6Meta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/results.vue?macro=true";
import { default as indexRi6rcZAGBbMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/index.vue?macro=true";
import { default as none0HtsNI1fMUMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/none.vue?macro=true";
import { default as profile6vqHyh9Dk9Meta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/profile.vue?macro=true";
import { default as imagei5hurRGkeuMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/answer/[answerId]/image.vue?macro=true";
import { default as indexeBbtzadfrCMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/index.vue?macro=true";
import { default as aggregateEsXORw3eN1Meta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue?macro=true";
import { default as indexTER3hB0ANdMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/index.vue?macro=true";
import { default as profileqZzLKi9mC7Meta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/profile.vue?macro=true";
import { default as resultsREGSUjFEagMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/results.vue?macro=true";
import { default as _91roundId_93YH5g38QneQMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue?macro=true";
import { default as createnDItgQx6OoMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue?macro=true";
import { default as _91userId_93kXG6u3yfvPMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue?macro=true";
import { default as indexZDIOrL7BNAMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue?macro=true";
import { default as createMo5iuAyqfpMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/create.vue?macro=true";
import { default as indexnhAqBfj47fMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/index.vue?macro=true";
import { default as loginvvXUcVm3FTMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/login.vue?macro=true";
import { default as registerQ0LLz0JF9aMeta } from "/codebuild/output/src3979743689/src/sense-us.app/pages/register.vue?macro=true";
export default [
  {
    name: indexDaotkr3TZ7Meta?.name ?? "projectId-roundId___en",
    path: indexDaotkr3TZ7Meta?.path ?? "/:projectId()/:roundId()",
    meta: indexDaotkr3TZ7Meta || {},
    alias: indexDaotkr3TZ7Meta?.alias || [],
    redirect: indexDaotkr3TZ7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaotkr3TZ7Meta?.name ?? "projectId-roundId___nl",
    path: indexDaotkr3TZ7Meta?.path ?? "/nl/:projectId()/:roundId()",
    meta: indexDaotkr3TZ7Meta || {},
    alias: indexDaotkr3TZ7Meta?.alias || [],
    redirect: indexDaotkr3TZ7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaotkr3TZ7Meta?.name ?? "projectId-roundId___de",
    path: indexDaotkr3TZ7Meta?.path ?? "/de/:projectId()/:roundId()",
    meta: indexDaotkr3TZ7Meta || {},
    alias: indexDaotkr3TZ7Meta?.alias || [],
    redirect: indexDaotkr3TZ7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDaotkr3TZ7Meta?.name ?? "projectId-roundId___es",
    path: indexDaotkr3TZ7Meta?.path ?? "/es/:projectId()/:roundId()",
    meta: indexDaotkr3TZ7Meta || {},
    alias: indexDaotkr3TZ7Meta?.alias || [],
    redirect: indexDaotkr3TZ7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: resultsMxHAlq0HK6Meta?.name ?? "projectId-roundId-results___en",
    path: resultsMxHAlq0HK6Meta?.path ?? "/:projectId()/:roundId()/results",
    meta: resultsMxHAlq0HK6Meta || {},
    alias: resultsMxHAlq0HK6Meta?.alias || [],
    redirect: resultsMxHAlq0HK6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsMxHAlq0HK6Meta?.name ?? "projectId-roundId-results___nl",
    path: resultsMxHAlq0HK6Meta?.path ?? "/nl/:projectId()/:roundId()/results",
    meta: resultsMxHAlq0HK6Meta || {},
    alias: resultsMxHAlq0HK6Meta?.alias || [],
    redirect: resultsMxHAlq0HK6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsMxHAlq0HK6Meta?.name ?? "projectId-roundId-results___de",
    path: resultsMxHAlq0HK6Meta?.path ?? "/de/:projectId()/:roundId()/results",
    meta: resultsMxHAlq0HK6Meta || {},
    alias: resultsMxHAlq0HK6Meta?.alias || [],
    redirect: resultsMxHAlq0HK6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsMxHAlq0HK6Meta?.name ?? "projectId-roundId-results___es",
    path: resultsMxHAlq0HK6Meta?.path ?? "/es/:projectId()/:roundId()/results",
    meta: resultsMxHAlq0HK6Meta || {},
    alias: resultsMxHAlq0HK6Meta?.alias || [],
    redirect: resultsMxHAlq0HK6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: indexRi6rcZAGBbMeta?.name ?? "projectId___en",
    path: indexRi6rcZAGBbMeta?.path ?? "/:projectId()",
    meta: indexRi6rcZAGBbMeta || {},
    alias: indexRi6rcZAGBbMeta?.alias || [],
    redirect: indexRi6rcZAGBbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRi6rcZAGBbMeta?.name ?? "projectId___nl",
    path: indexRi6rcZAGBbMeta?.path ?? "/nl/:projectId()",
    meta: indexRi6rcZAGBbMeta || {},
    alias: indexRi6rcZAGBbMeta?.alias || [],
    redirect: indexRi6rcZAGBbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRi6rcZAGBbMeta?.name ?? "projectId___de",
    path: indexRi6rcZAGBbMeta?.path ?? "/de/:projectId()",
    meta: indexRi6rcZAGBbMeta || {},
    alias: indexRi6rcZAGBbMeta?.alias || [],
    redirect: indexRi6rcZAGBbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRi6rcZAGBbMeta?.name ?? "projectId___es",
    path: indexRi6rcZAGBbMeta?.path ?? "/es/:projectId()",
    meta: indexRi6rcZAGBbMeta || {},
    alias: indexRi6rcZAGBbMeta?.alias || [],
    redirect: indexRi6rcZAGBbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: none0HtsNI1fMUMeta?.name ?? "projectId-none___en",
    path: none0HtsNI1fMUMeta?.path ?? "/:projectId()/none",
    meta: none0HtsNI1fMUMeta || {},
    alias: none0HtsNI1fMUMeta?.alias || [],
    redirect: none0HtsNI1fMUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: none0HtsNI1fMUMeta?.name ?? "projectId-none___nl",
    path: none0HtsNI1fMUMeta?.path ?? "/nl/:projectId()/none",
    meta: none0HtsNI1fMUMeta || {},
    alias: none0HtsNI1fMUMeta?.alias || [],
    redirect: none0HtsNI1fMUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: none0HtsNI1fMUMeta?.name ?? "projectId-none___de",
    path: none0HtsNI1fMUMeta?.path ?? "/de/:projectId()/none",
    meta: none0HtsNI1fMUMeta || {},
    alias: none0HtsNI1fMUMeta?.alias || [],
    redirect: none0HtsNI1fMUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: none0HtsNI1fMUMeta?.name ?? "projectId-none___es",
    path: none0HtsNI1fMUMeta?.path ?? "/es/:projectId()/none",
    meta: none0HtsNI1fMUMeta || {},
    alias: none0HtsNI1fMUMeta?.alias || [],
    redirect: none0HtsNI1fMUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: profile6vqHyh9Dk9Meta?.name ?? "projectId-profile___en",
    path: profile6vqHyh9Dk9Meta?.path ?? "/:projectId()/profile",
    meta: profile6vqHyh9Dk9Meta || {},
    alias: profile6vqHyh9Dk9Meta?.alias || [],
    redirect: profile6vqHyh9Dk9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile6vqHyh9Dk9Meta?.name ?? "projectId-profile___nl",
    path: profile6vqHyh9Dk9Meta?.path ?? "/nl/:projectId()/profile",
    meta: profile6vqHyh9Dk9Meta || {},
    alias: profile6vqHyh9Dk9Meta?.alias || [],
    redirect: profile6vqHyh9Dk9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile6vqHyh9Dk9Meta?.name ?? "projectId-profile___de",
    path: profile6vqHyh9Dk9Meta?.path ?? "/de/:projectId()/profile",
    meta: profile6vqHyh9Dk9Meta || {},
    alias: profile6vqHyh9Dk9Meta?.alias || [],
    redirect: profile6vqHyh9Dk9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profile6vqHyh9Dk9Meta?.name ?? "projectId-profile___es",
    path: profile6vqHyh9Dk9Meta?.path ?? "/es/:projectId()/profile",
    meta: profile6vqHyh9Dk9Meta || {},
    alias: profile6vqHyh9Dk9Meta?.alias || [],
    redirect: profile6vqHyh9Dk9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: imagei5hurRGkeuMeta?.name ?? "admin-answer-answerId-image___en",
    path: imagei5hurRGkeuMeta?.path ?? "/admin/answer/:answerId()/image",
    meta: imagei5hurRGkeuMeta || {},
    alias: imagei5hurRGkeuMeta?.alias || [],
    redirect: imagei5hurRGkeuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imagei5hurRGkeuMeta?.name ?? "admin-answer-answerId-image___nl",
    path: imagei5hurRGkeuMeta?.path ?? "/nl/admin/answer/:answerId()/image",
    meta: imagei5hurRGkeuMeta || {},
    alias: imagei5hurRGkeuMeta?.alias || [],
    redirect: imagei5hurRGkeuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imagei5hurRGkeuMeta?.name ?? "admin-answer-answerId-image___de",
    path: imagei5hurRGkeuMeta?.path ?? "/de/admin/answer/:answerId()/image",
    meta: imagei5hurRGkeuMeta || {},
    alias: imagei5hurRGkeuMeta?.alias || [],
    redirect: imagei5hurRGkeuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imagei5hurRGkeuMeta?.name ?? "admin-answer-answerId-image___es",
    path: imagei5hurRGkeuMeta?.path ?? "/es/admin/answer/:answerId()/image",
    meta: imagei5hurRGkeuMeta || {},
    alias: imagei5hurRGkeuMeta?.alias || [],
    redirect: imagei5hurRGkeuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: indexeBbtzadfrCMeta?.name ?? "admin___en",
    path: indexeBbtzadfrCMeta?.path ?? "/admin",
    meta: indexeBbtzadfrCMeta || {},
    alias: indexeBbtzadfrCMeta?.alias || [],
    redirect: indexeBbtzadfrCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexeBbtzadfrCMeta?.name ?? "admin___nl",
    path: indexeBbtzadfrCMeta?.path ?? "/nl/admin",
    meta: indexeBbtzadfrCMeta || {},
    alias: indexeBbtzadfrCMeta?.alias || [],
    redirect: indexeBbtzadfrCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexeBbtzadfrCMeta?.name ?? "admin___de",
    path: indexeBbtzadfrCMeta?.path ?? "/de/admin",
    meta: indexeBbtzadfrCMeta || {},
    alias: indexeBbtzadfrCMeta?.alias || [],
    redirect: indexeBbtzadfrCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexeBbtzadfrCMeta?.name ?? "admin___es",
    path: indexeBbtzadfrCMeta?.path ?? "/es/admin",
    meta: indexeBbtzadfrCMeta || {},
    alias: indexeBbtzadfrCMeta?.alias || [],
    redirect: indexeBbtzadfrCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: aggregateEsXORw3eN1Meta?.name ?? "admin-project-projectId-aggregate___en",
    path: aggregateEsXORw3eN1Meta?.path ?? "/admin/project/:projectId()/aggregate",
    meta: aggregateEsXORw3eN1Meta || {},
    alias: aggregateEsXORw3eN1Meta?.alias || [],
    redirect: aggregateEsXORw3eN1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateEsXORw3eN1Meta?.name ?? "admin-project-projectId-aggregate___nl",
    path: aggregateEsXORw3eN1Meta?.path ?? "/nl/admin/project/:projectId()/aggregate",
    meta: aggregateEsXORw3eN1Meta || {},
    alias: aggregateEsXORw3eN1Meta?.alias || [],
    redirect: aggregateEsXORw3eN1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateEsXORw3eN1Meta?.name ?? "admin-project-projectId-aggregate___de",
    path: aggregateEsXORw3eN1Meta?.path ?? "/de/admin/project/:projectId()/aggregate",
    meta: aggregateEsXORw3eN1Meta || {},
    alias: aggregateEsXORw3eN1Meta?.alias || [],
    redirect: aggregateEsXORw3eN1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateEsXORw3eN1Meta?.name ?? "admin-project-projectId-aggregate___es",
    path: aggregateEsXORw3eN1Meta?.path ?? "/es/admin/project/:projectId()/aggregate",
    meta: aggregateEsXORw3eN1Meta || {},
    alias: aggregateEsXORw3eN1Meta?.alias || [],
    redirect: aggregateEsXORw3eN1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: indexTER3hB0ANdMeta?.name ?? "admin-project-projectId___en",
    path: indexTER3hB0ANdMeta?.path ?? "/admin/project/:projectId()",
    meta: indexTER3hB0ANdMeta || {},
    alias: indexTER3hB0ANdMeta?.alias || [],
    redirect: indexTER3hB0ANdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTER3hB0ANdMeta?.name ?? "admin-project-projectId___nl",
    path: indexTER3hB0ANdMeta?.path ?? "/nl/admin/project/:projectId()",
    meta: indexTER3hB0ANdMeta || {},
    alias: indexTER3hB0ANdMeta?.alias || [],
    redirect: indexTER3hB0ANdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTER3hB0ANdMeta?.name ?? "admin-project-projectId___de",
    path: indexTER3hB0ANdMeta?.path ?? "/de/admin/project/:projectId()",
    meta: indexTER3hB0ANdMeta || {},
    alias: indexTER3hB0ANdMeta?.alias || [],
    redirect: indexTER3hB0ANdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTER3hB0ANdMeta?.name ?? "admin-project-projectId___es",
    path: indexTER3hB0ANdMeta?.path ?? "/es/admin/project/:projectId()",
    meta: indexTER3hB0ANdMeta || {},
    alias: indexTER3hB0ANdMeta?.alias || [],
    redirect: indexTER3hB0ANdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: profileqZzLKi9mC7Meta?.name ?? "admin-project-projectId-profile___en",
    path: profileqZzLKi9mC7Meta?.path ?? "/admin/project/:projectId()/profile",
    meta: profileqZzLKi9mC7Meta || {},
    alias: profileqZzLKi9mC7Meta?.alias || [],
    redirect: profileqZzLKi9mC7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileqZzLKi9mC7Meta?.name ?? "admin-project-projectId-profile___nl",
    path: profileqZzLKi9mC7Meta?.path ?? "/nl/admin/project/:projectId()/profile",
    meta: profileqZzLKi9mC7Meta || {},
    alias: profileqZzLKi9mC7Meta?.alias || [],
    redirect: profileqZzLKi9mC7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileqZzLKi9mC7Meta?.name ?? "admin-project-projectId-profile___de",
    path: profileqZzLKi9mC7Meta?.path ?? "/de/admin/project/:projectId()/profile",
    meta: profileqZzLKi9mC7Meta || {},
    alias: profileqZzLKi9mC7Meta?.alias || [],
    redirect: profileqZzLKi9mC7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileqZzLKi9mC7Meta?.name ?? "admin-project-projectId-profile___es",
    path: profileqZzLKi9mC7Meta?.path ?? "/es/admin/project/:projectId()/profile",
    meta: profileqZzLKi9mC7Meta || {},
    alias: profileqZzLKi9mC7Meta?.alias || [],
    redirect: profileqZzLKi9mC7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: resultsREGSUjFEagMeta?.name ?? "admin-project-projectId-results___en",
    path: resultsREGSUjFEagMeta?.path ?? "/admin/project/:projectId()/results",
    meta: resultsREGSUjFEagMeta || {},
    alias: resultsREGSUjFEagMeta?.alias || [],
    redirect: resultsREGSUjFEagMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsREGSUjFEagMeta?.name ?? "admin-project-projectId-results___nl",
    path: resultsREGSUjFEagMeta?.path ?? "/nl/admin/project/:projectId()/results",
    meta: resultsREGSUjFEagMeta || {},
    alias: resultsREGSUjFEagMeta?.alias || [],
    redirect: resultsREGSUjFEagMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsREGSUjFEagMeta?.name ?? "admin-project-projectId-results___de",
    path: resultsREGSUjFEagMeta?.path ?? "/de/admin/project/:projectId()/results",
    meta: resultsREGSUjFEagMeta || {},
    alias: resultsREGSUjFEagMeta?.alias || [],
    redirect: resultsREGSUjFEagMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsREGSUjFEagMeta?.name ?? "admin-project-projectId-results___es",
    path: resultsREGSUjFEagMeta?.path ?? "/es/admin/project/:projectId()/results",
    meta: resultsREGSUjFEagMeta || {},
    alias: resultsREGSUjFEagMeta?.alias || [],
    redirect: resultsREGSUjFEagMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93YH5g38QneQMeta?.name ?? "admin-project-projectId-round-roundId___en",
    path: _91roundId_93YH5g38QneQMeta?.path ?? "/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93YH5g38QneQMeta || {},
    alias: _91roundId_93YH5g38QneQMeta?.alias || [],
    redirect: _91roundId_93YH5g38QneQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93YH5g38QneQMeta?.name ?? "admin-project-projectId-round-roundId___nl",
    path: _91roundId_93YH5g38QneQMeta?.path ?? "/nl/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93YH5g38QneQMeta || {},
    alias: _91roundId_93YH5g38QneQMeta?.alias || [],
    redirect: _91roundId_93YH5g38QneQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93YH5g38QneQMeta?.name ?? "admin-project-projectId-round-roundId___de",
    path: _91roundId_93YH5g38QneQMeta?.path ?? "/de/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93YH5g38QneQMeta || {},
    alias: _91roundId_93YH5g38QneQMeta?.alias || [],
    redirect: _91roundId_93YH5g38QneQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93YH5g38QneQMeta?.name ?? "admin-project-projectId-round-roundId___es",
    path: _91roundId_93YH5g38QneQMeta?.path ?? "/es/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93YH5g38QneQMeta || {},
    alias: _91roundId_93YH5g38QneQMeta?.alias || [],
    redirect: _91roundId_93YH5g38QneQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: createnDItgQx6OoMeta?.name ?? "admin-project-projectId-round-create___en",
    path: createnDItgQx6OoMeta?.path ?? "/admin/project/:projectId()/round/create",
    meta: createnDItgQx6OoMeta || {},
    alias: createnDItgQx6OoMeta?.alias || [],
    redirect: createnDItgQx6OoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createnDItgQx6OoMeta?.name ?? "admin-project-projectId-round-create___nl",
    path: createnDItgQx6OoMeta?.path ?? "/nl/admin/project/:projectId()/round/create",
    meta: createnDItgQx6OoMeta || {},
    alias: createnDItgQx6OoMeta?.alias || [],
    redirect: createnDItgQx6OoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createnDItgQx6OoMeta?.name ?? "admin-project-projectId-round-create___de",
    path: createnDItgQx6OoMeta?.path ?? "/de/admin/project/:projectId()/round/create",
    meta: createnDItgQx6OoMeta || {},
    alias: createnDItgQx6OoMeta?.alias || [],
    redirect: createnDItgQx6OoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createnDItgQx6OoMeta?.name ?? "admin-project-projectId-round-create___es",
    path: createnDItgQx6OoMeta?.path ?? "/es/admin/project/:projectId()/round/create",
    meta: createnDItgQx6OoMeta || {},
    alias: createnDItgQx6OoMeta?.alias || [],
    redirect: createnDItgQx6OoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93kXG6u3yfvPMeta?.name ?? "admin-project-projectId-users-userId___en",
    path: _91userId_93kXG6u3yfvPMeta?.path ?? "/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93kXG6u3yfvPMeta || {},
    alias: _91userId_93kXG6u3yfvPMeta?.alias || [],
    redirect: _91userId_93kXG6u3yfvPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93kXG6u3yfvPMeta?.name ?? "admin-project-projectId-users-userId___nl",
    path: _91userId_93kXG6u3yfvPMeta?.path ?? "/nl/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93kXG6u3yfvPMeta || {},
    alias: _91userId_93kXG6u3yfvPMeta?.alias || [],
    redirect: _91userId_93kXG6u3yfvPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93kXG6u3yfvPMeta?.name ?? "admin-project-projectId-users-userId___de",
    path: _91userId_93kXG6u3yfvPMeta?.path ?? "/de/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93kXG6u3yfvPMeta || {},
    alias: _91userId_93kXG6u3yfvPMeta?.alias || [],
    redirect: _91userId_93kXG6u3yfvPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93kXG6u3yfvPMeta?.name ?? "admin-project-projectId-users-userId___es",
    path: _91userId_93kXG6u3yfvPMeta?.path ?? "/es/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93kXG6u3yfvPMeta || {},
    alias: _91userId_93kXG6u3yfvPMeta?.alias || [],
    redirect: _91userId_93kXG6u3yfvPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexZDIOrL7BNAMeta?.name ?? "admin-project-projectId-users___en",
    path: indexZDIOrL7BNAMeta?.path ?? "/admin/project/:projectId()/users",
    meta: indexZDIOrL7BNAMeta || {},
    alias: indexZDIOrL7BNAMeta?.alias || [],
    redirect: indexZDIOrL7BNAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDIOrL7BNAMeta?.name ?? "admin-project-projectId-users___nl",
    path: indexZDIOrL7BNAMeta?.path ?? "/nl/admin/project/:projectId()/users",
    meta: indexZDIOrL7BNAMeta || {},
    alias: indexZDIOrL7BNAMeta?.alias || [],
    redirect: indexZDIOrL7BNAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDIOrL7BNAMeta?.name ?? "admin-project-projectId-users___de",
    path: indexZDIOrL7BNAMeta?.path ?? "/de/admin/project/:projectId()/users",
    meta: indexZDIOrL7BNAMeta || {},
    alias: indexZDIOrL7BNAMeta?.alias || [],
    redirect: indexZDIOrL7BNAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDIOrL7BNAMeta?.name ?? "admin-project-projectId-users___es",
    path: indexZDIOrL7BNAMeta?.path ?? "/es/admin/project/:projectId()/users",
    meta: indexZDIOrL7BNAMeta || {},
    alias: indexZDIOrL7BNAMeta?.alias || [],
    redirect: indexZDIOrL7BNAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: createMo5iuAyqfpMeta?.name ?? "admin-project-create___en",
    path: createMo5iuAyqfpMeta?.path ?? "/admin/project/create",
    meta: createMo5iuAyqfpMeta || {},
    alias: createMo5iuAyqfpMeta?.alias || [],
    redirect: createMo5iuAyqfpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createMo5iuAyqfpMeta?.name ?? "admin-project-create___nl",
    path: createMo5iuAyqfpMeta?.path ?? "/nl/admin/project/create",
    meta: createMo5iuAyqfpMeta || {},
    alias: createMo5iuAyqfpMeta?.alias || [],
    redirect: createMo5iuAyqfpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createMo5iuAyqfpMeta?.name ?? "admin-project-create___de",
    path: createMo5iuAyqfpMeta?.path ?? "/de/admin/project/create",
    meta: createMo5iuAyqfpMeta || {},
    alias: createMo5iuAyqfpMeta?.alias || [],
    redirect: createMo5iuAyqfpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createMo5iuAyqfpMeta?.name ?? "admin-project-create___es",
    path: createMo5iuAyqfpMeta?.path ?? "/es/admin/project/create",
    meta: createMo5iuAyqfpMeta || {},
    alias: createMo5iuAyqfpMeta?.alias || [],
    redirect: createMo5iuAyqfpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: indexnhAqBfj47fMeta?.name ?? "index___en",
    path: indexnhAqBfj47fMeta?.path ?? "/",
    meta: indexnhAqBfj47fMeta || {},
    alias: indexnhAqBfj47fMeta?.alias || [],
    redirect: indexnhAqBfj47fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnhAqBfj47fMeta?.name ?? "index___nl",
    path: indexnhAqBfj47fMeta?.path ?? "/nl",
    meta: indexnhAqBfj47fMeta || {},
    alias: indexnhAqBfj47fMeta?.alias || [],
    redirect: indexnhAqBfj47fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnhAqBfj47fMeta?.name ?? "index___de",
    path: indexnhAqBfj47fMeta?.path ?? "/de",
    meta: indexnhAqBfj47fMeta || {},
    alias: indexnhAqBfj47fMeta?.alias || [],
    redirect: indexnhAqBfj47fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnhAqBfj47fMeta?.name ?? "index___es",
    path: indexnhAqBfj47fMeta?.path ?? "/es",
    meta: indexnhAqBfj47fMeta || {},
    alias: indexnhAqBfj47fMeta?.alias || [],
    redirect: indexnhAqBfj47fMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginvvXUcVm3FTMeta?.name ?? "login___en",
    path: loginvvXUcVm3FTMeta?.path ?? "/login",
    meta: loginvvXUcVm3FTMeta || {},
    alias: loginvvXUcVm3FTMeta?.alias || [],
    redirect: loginvvXUcVm3FTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginvvXUcVm3FTMeta?.name ?? "login___nl",
    path: loginvvXUcVm3FTMeta?.path ?? "/nl/login",
    meta: loginvvXUcVm3FTMeta || {},
    alias: loginvvXUcVm3FTMeta?.alias || [],
    redirect: loginvvXUcVm3FTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginvvXUcVm3FTMeta?.name ?? "login___de",
    path: loginvvXUcVm3FTMeta?.path ?? "/de/login",
    meta: loginvvXUcVm3FTMeta || {},
    alias: loginvvXUcVm3FTMeta?.alias || [],
    redirect: loginvvXUcVm3FTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginvvXUcVm3FTMeta?.name ?? "login___es",
    path: loginvvXUcVm3FTMeta?.path ?? "/es/login",
    meta: loginvvXUcVm3FTMeta || {},
    alias: loginvvXUcVm3FTMeta?.alias || [],
    redirect: loginvvXUcVm3FTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerQ0LLz0JF9aMeta?.name ?? "register___en",
    path: registerQ0LLz0JF9aMeta?.path ?? "/register",
    meta: registerQ0LLz0JF9aMeta || {},
    alias: registerQ0LLz0JF9aMeta?.alias || [],
    redirect: registerQ0LLz0JF9aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ0LLz0JF9aMeta?.name ?? "register___nl",
    path: registerQ0LLz0JF9aMeta?.path ?? "/nl/register",
    meta: registerQ0LLz0JF9aMeta || {},
    alias: registerQ0LLz0JF9aMeta?.alias || [],
    redirect: registerQ0LLz0JF9aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ0LLz0JF9aMeta?.name ?? "register___de",
    path: registerQ0LLz0JF9aMeta?.path ?? "/de/register",
    meta: registerQ0LLz0JF9aMeta || {},
    alias: registerQ0LLz0JF9aMeta?.alias || [],
    redirect: registerQ0LLz0JF9aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ0LLz0JF9aMeta?.name ?? "register___es",
    path: registerQ0LLz0JF9aMeta?.path ?? "/es/register",
    meta: registerQ0LLz0JF9aMeta || {},
    alias: registerQ0LLz0JF9aMeta?.alias || [],
    redirect: registerQ0LLz0JF9aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3979743689/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  }
]